import jQuery from "jquery";
import popper from "popper.js";
import bootstrap from "bootstrap";
import SmoothScroll from 'smooth-scroll';
// import magnificPopup from 'magnific-popup';
// import jInvertScroll from '../js/jquery.jInvertScroll.js';
// import fullpage from '../js/fullpage';
// import horizon from '../js/jquery.horizonScroll';
// import swipe from "../js/jquery.touchSwipe";
// import tiltedpage_scroll from "../js/jquery.tiltedpage-scroll";


window.$ = window.jQuery = window.jquery = jQuery;
window.bootstrap = bootstrap;
window.popper = popper;

// $('.video-pop-up').magnificPopup({
//   type: 'iframe',
//   mainClass: 'mfp-fade',
//   removalDelay: 160,
//   preloader: false,
//   fixedContentPos: false
// });

// $.magnificPopup.open({
//   items: {
//     src: 'reshed.webp'
//   },
//   type: 'image'
// });

// $('.img-pop-up').magnificPopup({
//   type: 'image',
//   gallery: {
//       enabled: true
//   }
// });

$(window).on('scroll', function() {
  if ($(window).scrollTop() < 300) {
    $('.site-navigation').removeClass('sticky_header');
  } else {
    $('.site-navigation').addClass('sticky_header');
  }
});

var scroll = new SmoothScroll('a[data-scroll]');

$('.hamburger-menu').on('click', function() {
  $(this).toggleClass('open');
  $('.site-navigation').toggleClass('show');
});

var pathname = location.pathname.split("/");
console.log(pathname);
$('.site-navigation a[href="' + pathname[pathname.length - 1] + '"]').parent().addClass('current-menu-item');

// $('a.flip').on('click', function(e) {
//   e.preventDefault();
// })

// var elem = $.jInvertScroll(['.scroll']);

// $('#tile-section').fullpage({
//   sectionSelector: '.scroll',
//   // slideSelector: '.scroll',
//   scrollHorizontally: true,
//   // controlArrows: false
//   // more options here
//   navigation: true,
//   responsiveWidth: 900,
//   // slidesNavigation: true,
// });

// $(window).on('mousewheel DOMMouseScroll', function(e) {
//   if (e.originalEvent.detail > 0) {
//     //scroll down
//     $('.slide-1').removeClass('active');
//     $('.slide-2').addClass('active');
//   } else {
//     //scroll up
//     $('.slide-1').addClass('active');
//     $('.slide-2').removeClass('active');
//   }
//   //prevent page fom scrolling
//   return false;
// });

// $('.scroll').horizon();

// $(".container").tiltedpage_scroll({
//   sectionContainer: "> .scroll",
//   angle: 50,
//   opacity: true,
//   scale: true,
//   outAnimation: true
// });